window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');

    const bootstrap = window.bootstrap = require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from 'laravel-echo';
window.io = require('socket.io-client');

window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + ':6001'
});

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });


// window.Echo.join(`chat-conversation.1`)
//     .here((users) => {
//         console.log("here");
//         console.log(users);
//     })
//     .joining((user) => {
//         console.log("joining");
//         console.log(user);
//     })
//     .leaving((user) => {
//         console.log("leaving");
//         console.log(user);
//     })
//     .error((error) => {
//         console.log("error");
//         console.error(error);
//     }).listen('.sendmessage', (e) => {
//         console.log(e);
//         alert('hello');
//     }).listenForWhisper('typing', (e) => {
//         console.log(e);
//     });
if(typeof window.conversationid !== 'undefined'){
    window.Echo.join(`chat-conversation.${window.conversationid}`)
    .error((error) => {
        console.log("error");
        console.error(error);
    }).listen('.sendmessage', (e) => {
        console.log(e);
        alert('hello');
    }).listenForWhisper('typing', (e) => {
        console.log(e);
    });
}

window.Echo.join(`chat-conversation`)
.error((error) => {
    console.log("error");
    console.error(error);
}).listen('.sendmessage', (e) => {
    console.log(e);
    alert('hello');
}).listenForWhisper('newconversation', (e) => {
    if($('.chat-liste-conver').length){
        var id = 0;
        if($('.chat-liste-conver-link.selected').length){
            id = $('.chat-liste-conver-link.selected').data('conversation_id');
        }
        console.log('newconversation');
        refreachConversation(id);
    }else{
        console.log('newconversation-2');
        axios
        .get(
            `/chat/my-notifications`
        )
        .then(response => {
            var unreadall = 0;
            if(typeof response.data !== 'undefined'){
                unreadall = response.data.unreadCount;
            }
            if(unreadall){
                $('.nbr-chatnotif').html(unreadall);
                $('.nbr-chatnotif').show();
            }else{
                $('.nbr-chatnotif').hide();
            }
        });
    }
});


// window.Echo.private(`chat-conversation.${window.conversationid}`).listen('.sendmessage', (e) => {
//     console.log(e);
//     alert('hello');
// });


window.refreachConversation = function(id) {
    axios
    .get(
        `/chat/my-conversations`
    )
    .then(response => {
        var unreadall = 0;
        jQuery.each(response.data, function(i, element)  {
            if($('.conversation_'+element.conversation_id).length){
                $('.conversation_'+element.conversation_id).insertBefore('ul.chat-liste-conver li:eq('+i+')');
            }else{
                var selected = "";
                var countnotif = "";
                if(id == element.conversation_id){
                    selected = "selected";
                }

                
                var html = '<li class="chat-liste-conver-link conversation_'+element.conversation_id+' '+selected+'" data-uid="'+element.messageable_id+'" data-conversation_id="'+element.conversation_id+'"  data-name="'+element.name+'"><div class="avatar avatar-lg"><span class="avatar-title rounded-circle">'+element.avatare+'</span></div><div class="title"></div>'+countnotif+'</li>';

                $(html).insertBefore('ul.chat-liste-conver li:eq('+i+')');
            }
            if(element.last_message != null){
                $('.conversation_'+element.conversation_id+' .title').html(element.name+'<p>'+element.last_message+'</p>');
            }else{
                $('.conversation_'+element.conversation_id+' .title').html(element.name);
            }

            if(element.unreadCount){
                if($('.conversation_'+element.conversation_id+' .bg-secondary').length){
                    $('.conversation_'+element.conversation_id+' .bg-secondary').html(element.unreadCount);
                }else{
                    countnotif = '<span class="badge bg-secondary rounded-pill  mt-1 mt-md-0">'+element.unreadCount+'</span>';
                    $('.conversation_'+element.conversation_id+'').append(countnotif);
                }
            }else{
                $('.conversation_'+element.conversation_id+' .bg-secondary').remove();
            }
            unreadall = unreadall + element.unreadCount;
        });
        if(unreadall){
            $('.nbr-chatnotif').html(unreadall);
            $('.nbr-chatnotif').show();
        }else{
            $('.nbr-chatnotif').hide();
        }
        
        psc.update();
    });
}
