$(document).ready(function () {
    $(document).on('click', '.open-chat', function() {
        var id = $(this).data('id');
        var type = $(this).data('type');
        const payload = {
            participants: [
              {
                id: window.participant.id,
                type: window.participant.type
              },
              {
                id: id,
                type: type
              }
            ]
        };

        axios.post(window.urlChat, payload)
            .then((response) => response.data)
            .then(async (data) => {
                console.log(data);
                window.Echo.join('chat-conversation').whisper('newconversation', {
                    typing: true
                });
                window.location.href = window.urlChatConv+"/"+data.id;

            })
            .catch((error) => {
                //showStatusMessage('failure', error.response.data.message);
                console.log(error);
            });
        return false;
    });


    $(document).on('click', '.send-message', function() {
        var id = $(this).data('id');
        window.send_chat_message(id);
        return false;
    });


    $(document).on('click', '.send-message-delete', function() {
        var id = $(this).data('id');
        axios
        .delete(`/chat/conversations/${id}`)
        .then(() => {
          window.location.href = "/";
        });
        return false;
    });

    $(document).on('click', '.chat-liste-conver-link', function() {
        var id = $(this).data('conversation_id');
        $('.chat-liste-conver-link').removeClass("selected");
        $('.convertion-body .chat-message-block').css('max-height', ($('.conversation-content').height() - 220));
        $('.loaderchat').show();
        $(this).addClass("selected");
        axios
        .get(
          `/chat/conversations/${id}/messages?participant_id=${window.participant.id}&participant_type=${window.participant.type}`
        )
        .then(response => {
            var messages = response.data;
            $('.convertion-title').html($(this).data('name'));
            $('.chat-message-block').removeClass("d-none").addClass("d-block");
            $('.conversation-content .card-footer').removeClass("d-none").addClass("d-block");
            $('.chat-message-block-empty').removeClass("d-block").addClass("d-none");
            $('.chat-message-block').html("");
            $('.chat-message-block').attr("id",'id-conv-'+id);
            $('.send-message').data("id",id);
            $('.send-message-delete').data("id",id);
            $('.msg-text').data("idconver",id);
            $('.msg-text').val("");
            window.conversationid = id;
            jQuery.each(messages.data, function(i, element)  {
                var ava = element.sender.firstname.charAt(0)+element.sender.lastname.charAt(0);
                var date = new Date(element.created_at);
                var datstring = date.toLocaleString('fr-FR');
                

                if(element.is_sender){
                    $('.chat-message-block').prepend(`<div class="chat-message self"><div class="chat-message-content-w"><div class="chat-message-content">${element.body}</div></div><div class="chat-message-date">${datstring}</div><div class="chat-message-avatar avatar avatar-lg"><span class="avatar-title rounded-circle">${ava}</span></div></div>`);
                }else{
                    $('.chat-message-block').prepend(`<div class="chat-message"><div class="chat-message-content-w"><div class="chat-message-content">${element.body}</div></div><div class="chat-message-avatar avatar avatar-lg"><span class="avatar-title rounded-circle">${ava}</span></div><div class="chat-message-date">${datstring}</div></div>`);
                }
                
            });

            if(messages.next_page_url){
                console.log(messages.next_page_url);
                $('.chat-message-block').prepend(`<div class="chat-date-separator"><span  class="loadmorechat" data-url="${messages.next_page_url}">Charger plus</span></div>`);
            }

            var $messages_w = $('.conversation-content .chat-message-block');
            $messages_w.scrollTop($messages_w.prop("scrollHeight"));
            pscmessage.update();

            window.Echo.join(`chat-conversation.${window.conversationid}`)
            .error((error) => {
                console.log("error");
                console.error(error);
            }).listen('.sendmessage', (e) => {
                console.log(e);
                alert('hello');
            }).listenForWhisper('typing', (e) => {
                console.log(e);
            }).listenForWhisper('newmsg', (e) => {
                if($('.chat-message-block#id-conv-'+e.id).length){
                    $('.chat-message-block#id-conv-'+e.id).append(e.msg);  
                    var $messages_w = $('.conversation-content .chat-message-block');
                    $messages_w.scrollTop($messages_w.prop("scrollHeight"));
                    pscmessage.update();
                }
            });
            refreachConversation(id);
            $('.loaderchat').hide();
        });
        return false;
    });


    $('.msg-text').on('keydown', function(){

        var idconver = $('.msg-text').data('idconver');
        
        setTimeout( () => {
            window.Echo.join('chat-conversation.'+idconver).whisper('typing', {
                typing: true
            });
        }, 300)
    });

    $('.chat-input input').on('keypress', function (e) {
        if (e.which == 13) {
            var idconver = $('.msg-text').data('idconver');
            window.send_chat_message(idconver);
            return false;
        }
    });

    $(document).on('click', '.loadmorechat', function() {
        var url = $(this).data('url');
        $('.loaderchat').show();
        chat_message_loadmore(url);
        return false;
    });

});

window.add_full_chat_message = function($input) {
    var date = new Date();
    var datstring = date.toLocaleString('fr-FR');
    var msg = '<div class="chat-message self"><div class="chat-message-content-w"><div class="chat-message-content">' + $input + '</div></div><div class="chat-message-date">'+datstring+'</div><div class="chat-message-avatar avatar avatar-lg"><span class="avatar-title rounded-circle">'+userSocket.avatar+'</span></div></div>';

    var msgto = '<div class="chat-message"><div class="chat-message-content-w"><div class="chat-message-content">' + $input + '</div></div><div class="chat-message-avatar avatar avatar-lg"><span class="avatar-title rounded-circle">'+userSocket.avatar+'</span></div><div class="chat-message-date">'+datstring+'</div></div>';
    $('.chat-message-block').append(msg);
    var $messages_w = $('.conversation-content .chat-message-block');
    $messages_w.scrollTop($messages_w.prop("scrollHeight"));
    pscmessage.update();
    return msgto;
}

window.send_chat_message = function(id) {
    var newMessage = $('.msg-text').val();
    const payload = {
        message: {
            body: newMessage,
        },
        participant_id: window.participant.id,
        participant_type: window.participant.type,
    };

    $('.msg-text').val("");
    var msg = add_full_chat_message(newMessage);

    axios.post(window.urlChat+'/'+id+'/messages', payload)
    .then((response) => response.data)
    .then(async (data) => {
        //console.log(data);
        window.refreachConversation(id);
        window.Echo.join('chat-conversation').whisper('newconversation', {
            typing: true
        });
        window.Echo.join(`chat-conversation.${id}`).whisper('newmsg', {
            id: id,
            typing: true,
            msg: msg
        });
    })
    .catch((error) => {
        //showStatusMessage('failure', error.response.data.message);
        console.log(error);
    });
}

window.chat_message_loadmore = function(url) {
    console.log('load more');
    axios
    .get(
        url+`&participant_id=${window.participant.id}&participant_type=${window.participant.type}`
    )
    .then(response => {
        var messages = response.data;
        $('.chat-date-separator').remove();
        jQuery.each(messages.data, function(i, element)  {
            var ava = element.sender.firstname.charAt(0)+element.sender.lastname.charAt(0);
            var date = new Date(element.created_at);
            var datstring = date.toLocaleString('fr-FR');
            if(element.is_sender){
                $('.chat-message-block').prepend(`<div class="chat-message self"><div class="chat-message-content-w"><div class="chat-message-content">${element.body}</div></div><div class="chat-message-date">${datstring}</div><div class="chat-message-avatar avatar avatar-lg"><span class="avatar-title rounded-circle">${ava}</span></div></div>`);
            }else{
                $('.chat-message-block').prepend(`<div class="chat-message"><div class="chat-message-content-w"><div class="chat-message-content">${element.body}</div></div><div class="chat-message-avatar avatar avatar-lg"><span class="avatar-title rounded-circle">${ava}</span></div><div class="chat-message-date">${datstring}</div></div>`);
            }
            
        });

        if(messages.next_page_url){
            $('.chat-message-block').prepend(`<div class="chat-date-separator"><span class="loadmorechat" data-url="${messages.next_page_url}">Charger plus</span></div>`);
        }
        
        $('.loaderchat').hide();
    });
}