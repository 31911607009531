
// listening to prompt_zipcode event
// and open 'zipcodeModal' that prompts for user's zipcode
Livewire.on('prompt_zipcode', (productId) => {
    bootstrap.Modal.getOrCreateInstance(document.getElementById('zipcodeModal')).show();

    if(productId){
        Livewire.emit('set_product_id_onhold', productId);
    }
});

Livewire.on('zipcode_set', (productId) => {
    bootstrap.Modal.getOrCreateInstance(document.getElementById('zipcodeModal')).hide();

    if(productId){
        Livewire.emit(`add_to_cart_product_${productId}`);
    }
});