require('popper.js');
require('./bootstrap');

// Vendor
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import 'flickity-as-nav-for';
import 'flickity-fade';
import 'jarallax';
import PerfectScrollbar from 'perfect-scrollbar';
// Theme
import './aos';
import './bigpicture';
import './choices';
import './countup';
// import './dropzone';
import './highlight';
import './isotope';
import './jarallax';
import './map';
import './modal';
import './navbar';
import './navbar-dropdown';
import './popover';
import './pricing';
import './quill';
import './smooth-scroll';
import './tooltip';
import './typed';


//require('perfect-scrollbar/dist/perfect-scrollbar.min.js');

//window.PerfectScrollbar = require('perfect-scrollbar').default;

// User
import './user.js';

if(document.querySelector('.simple-pricing-carousel'))
{
    const flkty = new Flickity('.simple-pricing-carousel', {
        "pageDots": false,
        "cellAlign": "left",
        "wrapAround": true,
        on:{
            ready: function(){
                $('.simple-pricing-item').addClass('h-100');
            }
        }
    });
}

if(document.querySelector('.chat-liste-conver')){
    window.psc = new PerfectScrollbar('.chat-liste-conver');
}


if(document.querySelector('.convertion-body .chat-message-block')){
    window.pscmessage = new PerfectScrollbar('.convertion-body .chat-message-block');
}




